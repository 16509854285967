import { BestSellerFilters, BestSellerOrders } from '../types';

export const createDefaultOrders = (): BestSellerOrders => ({
  daily: '오늘의 베스트',
  weekly: '주간 베스트',
  monthly: '월간 베스트',
  steady: '스테디셀러',
  setbook: '인기 세트',
});

export const createDefaultFilters = (): BestSellerFilters => ({
  series_completed: '완결',
  rent: '대여',
  adult_include: '성인',
  adult_exclude: '성인 제외',
});
