import styled from '@emotion/styled';
import { ChangeEvent } from 'react';

import { IconAdultWithoutCircle } from '@/assets/svgs/system';
import { SwitchProps } from '@/components/common/Switch';

import * as styles from './AdultFilterToggle.styles';

interface AdultFilterToggleProps extends Pick<SwitchProps, 'checked' | 'onChange'> {
  checked: boolean;
}

const AdultSwitchContainer = styled.span<{ checked?: boolean }>`
  ${styles.containerStyle};
`;

const AdultSwitchInput = styled.input`
  ${styles.inputStyle};
`;

const AdultSwitchIconContainer = styled.span<{ checked?: boolean }>`
  ${({ checked }) => styles.iconContainerStyle(checked)};
`;

const AdultSwitchIconPlaceholder = styled.span<{ checked?: boolean }>`
  ${({ theme, checked }) => styles.iconPlaceholderStyle(theme, checked)};
`;

const AdultSwitchIconWrapper = styled.span`
  ${({ theme }) => styles.iconWrapperStyle(theme)};
`;

export const AdultFilterToggle = ({ checked, onChange, ...props }: AdultFilterToggleProps): ReactJSX.Element => {
  const onInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.checked);
  };

  return (
    <AdultSwitchContainer>
      <AdultSwitchIconPlaceholder checked={checked} />
      <AdultSwitchIconContainer checked={checked}>
        <AdultSwitchIconWrapper>
          <IconAdultWithoutCircle />
        </AdultSwitchIconWrapper>
      </AdultSwitchIconContainer>

      <AdultSwitchInput {...props} checked={checked} onChange={onInputChanged} type="checkbox" />
    </AdultSwitchContainer>
  );
};
