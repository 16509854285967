import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const containerStyle = css`
  /* container layout style */
  position: relative;
  display: flex;
  width: 50px;
  height: 30px;
  font-size: 26px;

  /* flex layout style */
  align-items: center;

  /* container behavior style */
  cursor: pointer;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 36px;
      height: 22px;
      font-size: 19px;
    `,
  )};
`;

export const inputStyle = css`
  /* container layout style */
  position: absolute;
  display: inline-flex;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;

  /* container behavior style */
  cursor: pointer;
`;

export const iconContainerStyle = (checked?: boolean): SerializedStyles => css`
  /* container layout style */
  display: flex;
  position: absolute;
  justify-content: center;
  width: 1em;
  height: 100%;
  transition: 0.2s;
  left: ${checked ? 'calc(100% - 1em)' : '0%'}; // (relative width size - content widh size)

  /* flex layout style */
  align-items: center;

  /* container behavior style */
  pointer-events: none;
`;

export const iconPlaceholderStyle = (theme: RIDITheme, checked?: boolean): SerializedStyles => css`
  /* container layout style */
  display: flex;
  width: 100%;
  height: 15px;
  border: none;
  border-radius: 15px;
  transition: 0.2s;
  background: ${checked ? theme.colors.red : theme.colors.grey400};

  /* flex layout style */
  justify-content: center;
  align-items: center;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      height: 11px;
    `,
  )};
`;

export const iconWrapperStyle = (theme: RIDITheme): SerializedStyles => css`
  /* container layout style */
  display: flex;
  border: 1.3px solid ${theme.colors.red};
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
  background-color: ${theme.colors.white};
  font-size: 20px;
  color: ${theme.colors.black};

  /* flex layout style */
  align-items: center;
  justify-content: center;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 15px;
      width: 19px;
      height: 19px;
    `,
  )};
`;
