import type { BestSellersQueryVariables, PageLimitInput } from '@ridi-web/gql-client-codegen';
import type { DehydratedState } from 'react-query';

import { GenreType } from '@/base/constants/genreMap';

export type BestSellerPageParams = {
  genre: GenreType;
};

export type BestSellerPageQuery = {
  adult_include?: string;
  adult_exclude?: string;
  order?: string;
  page?: string;
  rent?: string;
  series_completed?: string;
};

export type ExactBestsellersQueryVariables = BestSellersQueryVariables & {
  pageLimitInput: PageLimitInput;
};

export interface BestSellersPageProps {
  dehydratedState: DehydratedState;
  filters: BestSellerFilters | null;
  genre: GenreType;
  orders: BestSellerOrders;
  useGrid: boolean;
  variables: ExactBestsellersQueryVariables;
}

export const ORDER_KEY = ['daily', 'weekly', 'monthly', 'steady', 'setbook'] as const;
export type OrderKey = (typeof ORDER_KEY)[number];
export type BestSellerOrders = Partial<Record<OrderKey, string>>;

export type FilterKey = 'rent' | 'adult_include' | 'adult_exclude' | 'series_completed';
export type BestSellerFilters = Partial<Record<FilterKey, string>>;

export type QueryParams = {
  params: BestSellerPageParams;
  query: BestSellerPageQuery;
};
