/* eslint-disable @typescript-eslint/no-unused-vars */
import { AdultOption, BestsellerPeriod, SetBookOption } from '@ridi-web/gql-client-codegen';

import { BestSellerCategoryCode, GenreType } from '@/base/constants/genreMap';
import {
  BestSellerFilters,
  BestSellerOrders,
  ExactBestsellersQueryVariables,
  ORDER_KEY,
  OrderKey,
  QueryParams,
} from '@/utils/bestSellers/types';

import { createDefaultFilters, createDefaultOrders } from '../common';
import { ITEMS_PER_PAGE } from '../constants';

// 여기 파일 내용은 PM한테 한번씩 체크 해보고 수정할 것

const supportAdultFilterToggleTargets = {
  path: [
    '/bl/webnovel',
    '/romance/webnovel',
    '/romance-fantasy/webnovel',
    '/webtoon/recommendation',
    '/webtoon/romance',
  ],
  genre: [
    GenreType.GENRE_BL_NOVEL,
    GenreType.GENRE_ROMANCE_SERIAL,
    GenreType.GENRE_ROMANCE_FANTASY_SERIAL,
    GenreType.GENRE_WEBTOON,
    GenreType.GENRE_ROMANCE_WEBTOON,
  ],
};

/**
 * pathName 값이 서버에서 URL.pathname 형태로 오겠지만 혹시모를 쿼리스트링 유지
 * @returns pathname string with adult exclude query
 */
export const addAdultExcludeBestSellerPathName = (pathname: string) => {
  const [path, qs] = pathname.split('?');
  const searchParams = new URLSearchParams(qs);
  searchParams.set('adult_exclude', 'y');
  return `${path}?${searchParams.toString()}`;
};

export const createOrdersByGenre = (genre: GenreType): BestSellerOrders => {
  const defaultOrders = createDefaultOrders();
  switch (genre) {
    case GenreType.GENRE_GENERAL: {
      const { daily, ...filtered } = defaultOrders;
      return filtered;
    }
    case GenreType.GENRE_ROMANCE:
    case GenreType.GENRE_ROMANCE_FANTASY:
    case GenreType.GENRE_FANTASY:
    case GenreType.GENRE_COMIC:
    case GenreType.GENRE_BL_COMIC:
    case GenreType.GENRE_BL_NOVEL:
    case GenreType.GENRE_GEUKHWA: {
      const { daily, setbook, ...filtered } = defaultOrders;
      return filtered;
    }
    case GenreType.GENRE_BL_WEBNOVEL:
    case GenreType.GENRE_BL_WEBTOON:
    case GenreType.GENRE_ROMANCE_SERIAL:
    case GenreType.GENRE_ROMANCE_FANTASY_SERIAL:
    case GenreType.GENRE_FANTASY_SERIAL:
    case GenreType.GENRE_WEBTOON:
    case GenreType.GENRE_COMIC_SERIAL: {
      const { steady, setbook, ...filtered } = defaultOrders;
      return filtered;
    }
    default:
      break;
  }
  return defaultOrders;
};

export const createFiltersByGenre = (genre: GenreType): BestSellerFilters | null => {
  const defaultFilters = createDefaultFilters();
  switch (genre) {
    case GenreType.GENRE_GENERAL: {
      return null;
    }

    default:
      break;
  }
  return defaultFilters;
};

export const getDefaultOrderByGenre = (genre: GenreType) => {
  const obj = createOrdersByGenre(genre);
  return obj.daily ? ORDER_KEY[0] : ORDER_KEY[1];
};

/**
 * @Link [Slack Thread](https://ridi.slack.com/archives/C01SP5FT727/p1657877590822919?thread_ts=1655277775.836999&cid=C01SP5FT727)
 */
export const getPeriodFromOrder = (order: OrderKey): { period: BestsellerPeriod; setBookOption: SetBookOption } =>
  order === 'setbook'
    ? { period: BestsellerPeriod.Weekly, setBookOption: SetBookOption.OnlySetBook }
    : { period: order.toUpperCase() as BestsellerPeriod, setBookOption: SetBookOption.None };

export const getRecommendBestSellerVariables = (genre: GenreType): ExactBestsellersQueryVariables => {
  const categoryIncludes = BestSellerCategoryCode[genre];
  const period = getDefaultOrderByGenre(genre).toUpperCase() as BestsellerPeriod;
  return {
    adultOption: AdultOption.None,
    categoryIncludes,
    onlyRentAvailable: false,
    pageLimitInput: {
      limit: ITEMS_PER_PAGE,
      page: 1,
    },
    period,
    setBookOption: SetBookOption.None,
  };
};

export const isInvalidRecommendBestSellerQuery = (query: QueryParams['query']) =>
  (query.page && parseInt(query.page, 10) > 1) || query.order === 'setbook';

export const isInvalidRecommendBestSellerVariable = (variables: ExactBestsellersQueryVariables) =>
  variables.pageLimitInput?.page > 1 || variables.setBookOption === SetBookOption.OnlySetBook;

export const isSupportingAdultFilterGenre = ({ path, genre }: { path?: string; genre?: GenreType }) => {
  if (path) {
    return supportAdultFilterToggleTargets.path.includes(path);
  }
  if (genre) {
    return supportAdultFilterToggleTargets.genre.includes(genre);
  }
  return false;
};
